/* eslint-disable */
import { globalJsAssets } from "../globalAsstes";
export const fetchAssets = async (url, options) => {
  try {
    const res = await fetch(url, options);
    return res.text();
  } catch (error) {
    throw new Error("assets load error");
  }
};

const parseHtml = (htmlText) => {
  let fragment = document.createElement("html");
  fragment.innerHTML = htmlText;
  let body = fragment.querySelector("body");
  const links = fragment.querySelectorAll("link");
  const styles = fragment.querySelectorAll("style");
  const scripts = fragment.querySelectorAll("script");
  const tempBody = body.cloneNode(true);
  const bodyScripts = tempBody.querySelectorAll("script");
  // console.log(bodyScripts);
  bodyScripts.forEach((script) => tempBody.removeChild(script));
  // console.log(tempBody);
  fragment = null;
  body = null;
  return {
    links: [...links],
    styles: [...styles],
    scripts: [...scripts],
    body: tempBody,
  };
};

// const getLinksInfo = (link) => {
//   return {
//     rel: link.rel,
//     href: link.href,
//     baseURI: link.baseURI,
//     as: link.as,
//   };
// };

// const getStyleInfo = (style) => {
//   return {
//     outerText: style.outerText
//   }
// }

export const fetchHtml = async (conf, options, container) => {
  const htmlText = await fetchAssets(conf.url, options);
  // console.log(htmlText);
  if (!htmlText) {
    return new Error("空白资源");
  }
  const { links, scripts, styles, body } = parseHtml(htmlText);

  // console.log(links, scripts, styles);
  await handleLinks(links, container, conf);
  container.appendChild(body);
  handleScripts(scripts, container, conf);
  
};

const handleScripts = (scripts, container, conf) => {
  // console.log(globalJsAssets);
  const prefix = window.__micro_app_name__ || "app1";
  scripts.forEach((script, index) => {
    // inline script 只执行一次
    const assetsName = `${prefix}-script-${index}`;
    const assets = globalJsAssets.get(assetsName);
    if (!script.src) {
      // console.log("inline script", script);
      if (!assets) globalJsAssets.set(assetsName, script);
      const a = window.eval(script.innerText);
      // console.log(a);
    } else {
      // if (assets) return container.appendChild(assets);
      const fetchScript = document.createElement("script");
      fetchScript.type = script.type;
      fetchScript.src = script.src.replace(
        "http://" + window.location.host,
        conf.baseUrl
      );
      // console.log(fetchScript.src);
      container.appendChild(fetchScript);
      // if (!assets) globalJsAssets.set(assetsName, fetchScript);
    }
  });
};

const handleLinks = (links, container, conf) => {
  const lP = [];
  links.forEach((link) => {
    if (!link.href) return;
    // excludes favicon.ico
    if (
      link.href.indexOf("favicon.ico") > -1 ||
      link.rel.indexOf("icon") > -1
    ) {
      container.appendChild(link);
      return;
    }
    const linkTag = document.createElement("link");
    linkTag.href = link.href.replace(
      "http://" + window.location.host,
      conf.baseUrl
    );
    linkTag.rel = link.rel;
    if (link.as) linkTag.as = link.as;
    lP.push(loadAssets(linkTag, container));
  });
  return Promise.allSettled(lP);
};

export const fetchScript = () => {};

export const fetchLink = () => {};

function loadAssets(script, source) {
  return new Promise((res, rej) => {
    source.appendChild(script);
    script.onload = () => {
      // console.log("link", script.href);
      res("load succ");
    };
    script.onerror = () => {
      rej(new Error("11111"));
    };
  });
}
