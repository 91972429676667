<template>
  <header class="nav">
    <nav>
      <router-link
        to="/app1"
        custom
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          :class="[
            'nav-item',
            isActive && 'router-link-active',
            isExactActive && 'router-link-exact-active',
          ]"
          :active="isActive"
          :href="href"
          @click="switchPages('app1', navigate)"
        >
          app1
        </li>
      </router-link>
      <router-link
        to="/app2"
        custom
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          :class="[
            'nav-item',
            isActive && 'router-link-active',
            isExactActive && 'router-link-exact-active',
          ]"
          :active="isActive"
          :href="href"
          @click="switchPages('app2', navigate)"
        >
          app2
        </li>
      </router-link>
      <router-link
        to="/app3"
        custom
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          :class="[
            'nav-item',
            isActive && 'router-link-active',
            isExactActive && 'router-link-exact-active',
          ]"
          :active="isActive"
          :href="href"
           @click="switchPages('app3', navigate)"
        >
          app3
        </li>
      </router-link>
    </nav>
  </header>
  <router-view class="content"></router-view>
  <!-- <app-container :conf="currAppConf" :url="currAppConf.url"></app-container> -->
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      currApp: "app1",
    };
  },
  methods: {
    switchPages(name, navigate) {
      this.currApp = name;
      window.__micro_app_name__ = name;
      navigate();
    },
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
  display: inline-block;
  cursor: pointer;
}
.nav-item {
  padding: 0 20px;
}
.nav-item.router-link-active {
  border-bottom: 2px solid #ffd04b;
  color: #ffd04b;
}
#micro-app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100vw;
  height: 100vh;
  background-color: #f7f8fc;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.nav {
  display: flex;
  width: 100%;
  height: 64px;
  padding: 0 28 px 0 0;
  line-height: 64px;
  background-color: #364455;
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 12%), 0 4px 5px 0 rgb(0 0 0 / 8%),
    0 1px 10px 0 rgb(0 0 0 / 5%);
  height: 64px;
  color: #fff;
}
.content {
  display: block;
  flex: 1;
  flex-basis: auto;
}
</style>
