/* eslint-disable */
/**
 * no-sandbox
 */
import router from "../router";

export default (function (win, opt) {
  const winPropertyList = [];
  const ignoreList = [
    "__sandBox__",
    "__micro_app_name__",
    "__VUE_HMR_RUNTIME__",
    "__VUE__",
    "regeneratorRuntime",
    "setImmediate",
    "clearImmediate",
    "0",
  ];
  for (let p in win) {
    winPropertyList.push(p);
  }
  const body = document.body;

  class SandBox {
    constructor(options) {
      this.options = options || win.appConfig;
      this.bodyRecordList = [];
      this.instanceMap = new Map();
      // 记录body变化
      const mutations = new MutationObserver((record) => {
        this.bodyRecordList = record;
      });
      mutations.observe(body, {
        childList: true,
      });
    }

    get appName() {
      return this.appInfo?.name;
    }

    get appInfo() {
      // 简单校验 TODO:支持用户自定义校验
      const { pathname } = window.location;
      const ret = Object.values(this.appConfig).filter((config) => {
        const reg = new RegExp(`^\/${config.name}`);
        return reg.test(pathname);
      })[0];
      return ret;
    }

    get winPropertyList() {
      return winPropertyList;
    }

    getPropertyList(obj) {
      const ret = [];
      for (let p in obj) {
        ret.push(p);
      }
      return ret;
    }

    collectNewProps() {
      const newList = this.getPropertyList(window);
      return newList.filter(
        (prop) =>
          !this.winPropertyList.includes(prop) && !ignoreList.includes(prop)
      );
    }

    // 清除 影响
    removeEffects() {
      console.log(this.collectNewProps());
      // this.collectNewProps().forEach((prop) => {
      //   window[prop] = null;
      // });
      this.bodyRecordList.forEach((record) => {
        record.addedNodes.forEach((node) => {
          if (!node.parentNode) return;
          node.parentNode.removeChild(node);
          node = null;
        });
      });
    }

    setInstance(insInfo) {
      this.instanceMap.set(window.__micro_app_name__, insInfo);
    }

    // 注册 子应用信息
    registerApp(appConfig = null) {
      if (!appConfig) throw new Error("子应用信息为空");
      this.appConfig = appConfig;
    }
  }
  return new SandBox(opt);
})(window, {});
