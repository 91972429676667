/* eslint-disable */
import sandBox from "./micro/sandbox";
import { createApp } from "vue";
import router from "./router";
import App from "./App.vue";
const app = createApp(App);
app.use(router);
app.mount("#micro-app");
window.__sandBox__ = sandBox;
__sandBox__.registerApp(window.appConfig)
