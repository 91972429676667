import { createRouter, createWebHistory } from "vue-router";
import appContainer from '../components/appContainer.vue';

const Home = { template: '<div>Home</div>' }

const routes = [
  { path: '/', component: Home },
  // 微应用分配路由
  {
    path: '/app1/:pathMatch(.*)*',
    component: appContainer
  },
  {
    path: '/app2/:pathMatch(.*)*',
    component: appContainer
  },
  {
    path: '/app3/:pathMatch(.*)*',
    component: appContainer
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
