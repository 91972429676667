/* eslint-disable */
/**
 * 路由处理模块
 */

window.addEventListener(
  "hashchange",
  (e) => {
    console.log(e);
  },
  false
);

window.addEventListener(
  "popstate",
  (e) => {
    console.log("history", e, window.location.href);
    const { href } = window.location;
    const { state } = e;
    // 判断是否切换应用
    Promise.resolve().then(() => {
      const event = new CustomEvent("microRouterChange", {
        detail: {
          type: 'popstate',
          state,
          title: '',
          url: href
        },
      });
      window.dispatchEvent(event);
    });
  },
  false
);

// proxy history 方法

const historyProto = History.prototype;
const historyToPatch = ["pushState", "replaceState"];

historyToPatch.forEach((methods) => {
  const original = historyProto[methods];
  history[methods] = function (...arg) {
    original.apply(this, arg);
    const [state, title, url] = arg || [];
    Promise.resolve().then(() => {
      if(window.location.href !== url) return false;
      const event = new CustomEvent("microRouterChange", {
        detail: {
          type: methods,
          state,
          title,
          url,
        },
      });
      window.dispatchEvent(event);
    });
  };
});
