<template>
  <div ref="myiframe" class="micro" style="margin: 40px auto"></div>
</template>

<script>
/* eslint-disable */
import { fetchHtml } from "../micro/assets/fetch";

export default {
  methods: {
    async loadApp() {
      if (!window.__sandBox__.appInfo) return;
      window.__sandBox__.tempAppInfo = window.__sandBox__.appInfo;
      fetchHtml(
        window.__sandBox__.appInfo,
        {
          method: "GET",
          cache: "reload",
        },
        this.$refs.myiframe
      );
    },
    unInstallApp() {
      // 收集
      window.__sandBox__.removeEffects();
      const { name } = window.__sandBox__.tempAppInfo || {};
      // console.log(name)
      if (!name) return;
      const instance = window.__sandBox__.instanceMap.get(name);
      if (instance) {
        console.log("卸载应用", name);
        instance.uninstall?.();
        window.__sandBox__.instanceMap.delete(name);
      }
      // 内存优化
      this.$refs.myiframe.textContent = "";
    },
    update() {
      this.unInstallApp();
      try {
        this.loadApp();
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    console.log("load app");
    this.loadApp();
    console.log((window.appContainer = this));
    window.addEventListener("microRouterChange", (e) => {
      console.log("路由变化了");
      const { pathname } = window.location;
      this.update();
    });
  },
};
</script>
